import Popover, { PopoverProps } from '../messages/popover'
import styles from './menu-button.module.css'

interface VendiaMenuButtonProps extends Partial<PopoverProps> {
  className?: string
  children: React.ReactNode
  menuItems?: React.ReactNode[]
  menuItemContent?: React.ReactNode // Newer, more flexible way to pass in menu items
  align?: 'start' | 'end' | 'center'
  asChild?: boolean
}

const VendiaMenuButton = ({
  align,
  contentClassName,
  children,
  menuItems,
  menuItemContent,
  asChild = false,
  ...rest
}: VendiaMenuButtonProps) => {
  if (!menuItems && !menuItemContent) {
    throw new Error('VendiaMenuButton requires either menuItems or menuItemContent prop')
  }
  return (
    <Popover
      {...rest}
      align={align ?? 'end'}
      content={
        <div role='menu' className='max-h-[400px] overflow-y-auto whitespace-nowrap p-1 text-xs'>
          {menuItemContent
            ? menuItemContent
            : menuItems?.map((menuItem, i) => (
                <div role='menuitem' className='px-1 py-1' key={i}>
                  {menuItem}
                </div>
              ))}
        </div>
      }
      contentClassName={`${styles.PopoverContent} ${contentClassName}`}
      triggerAsChild={asChild}
    >
      <div data-testid='menu-button-trigger'>{children}</div>
    </Popover>
  )
}

export default VendiaMenuButton
