import clsx from 'clsx'
import { ReactNode } from 'react'

interface StepHeaderProps {
  title: ReactNode
  large?: boolean
  centered?: boolean
  hasMarginY?: boolean
  description?: ReactNode
  actions?: ReactNode
  className?: string
}
export const StepContentHeader = ({
  title,
  description,
  actions,
  large = false,
  centered = false,
  hasMarginY = false,
  className = '',
}: StepHeaderProps) => {
  return (
    <div
      className={clsx(
        `mb-8 flex w-full items-center`,
        hasMarginY && 'mt-6',
        centered && 'justify-center',
        !centered && 'justify-between',
        className,
      )}
    >
      <div className={clsx('flex flex-1 flex-col justify-center', centered ? 'items-center' : 'max-w-4xl')}>
        <h2
          className={clsx(description ? '!mb-2' : '!mb-0', large ? 'h1' : 'h2', centered ? 'text-center' : 'text-left')}
        >
          {title}
        </h2>
        {description && (
          <div className={clsx('text-light mb-2 text-base', centered && 'text-center')}>{description}</div>
        )}
      </div>
      {actions}
    </div>
  )
}
