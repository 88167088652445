import * as Popover from '@radix-ui/react-popover'
import clsx from 'clsx'
import { ReactNode } from 'react'

export type PopoverProps = {
  children: ReactNode
  content: ReactNode
  side?: Popover.PopperContentProps['side']
  sideOffset?: Popover.PopperContentProps['sideOffset']
  align?: Popover.PopperContentProps['align']
  alignOffset?: Popover.PopperContentProps['alignOffset']
  // See Radix docs for info, probably not useful generally helped with Org Roles preview
  anchor?: ReactNode
  // this allows the consumer to provide a classname(s) to the Popover.Content component (for transitions, etc.)
  contentClassName?: string
  triggerAsChild?: boolean
}

function PopoverComponent({
  children,
  content,
  side,
  sideOffset,
  align,
  alignOffset,
  anchor,
  contentClassName,
  triggerAsChild = false,
  ...props
}: PopoverProps) {
  return (
    <Popover.Root {...props}>
      {anchor && <Popover.Anchor asChild>{anchor}</Popover.Anchor>}
      <Popover.Trigger className='flex' onClick={(e) => e.stopPropagation()} asChild={triggerAsChild}>
        {children}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side={side}
          sideOffset={sideOffset}
          align={align}
          alignOffset={alignOffset}
          className={clsx('rounded-md bg-white p-1 shadow-xl', contentClassName)}
          onOpenAutoFocus={(e) => {
            // This prevents the popover from auto-focusing on the first item in menus
            e.preventDefault()
          }}
        >
          {content}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default PopoverComponent
