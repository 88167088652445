import clsx from 'clsx'

const Container = ({
  children,
  className,
  borderless,
}: {
  children: React.ReactNode
  className?: string
  borderless?: boolean
}) => {
  return (
    <div
      className={clsx(
        'flex w-full gap-4 overflow-x-auto',
        borderless ? '' : 'border-b-neutral-4 my-2 border-b',
        className,
      )}
    >
      {children}
    </div>
  )
}

interface TabButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  isActive: boolean
}

const Button = ({ isActive, ...props }: TabButtonProps) => {
  return (
    <button
      className={clsx(
        'flex items-center border border-x-0 border-b-0 px-4 py-3 transition-colors duration-300',
        isActive && 'border-b-brand text-dark border-b-4 border-transparent font-bold',
        !isActive && 'hover:border-b-brand text-dark border-b-4 border-transparent hover:text-opacity-100',
      )}
      {...props}
    />
  )
}

export default {
  Container,
  Button,
}
