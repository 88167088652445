import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Popover,
  PopoverButton,
  PopoverPanel,
  useClose,
} from '@headlessui/react'
import { UniSkuEnum } from '@vendia/management-api-types/src/generated/graphql/graphql'
import clsx from 'clsx'
import debug from 'debug'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Icon from 'src/components/icons/icon'
import PageLoader from 'src/components/loaders/page-loader'
import { UniFeatures } from 'src/types/types'
import { useGetUni } from 'src/utils/hooks/use-get-uni'
import useListUnis, { ListUnis } from 'src/utils/hooks/use-list-unis'

const logger = debug('app:RoleSwitcher')
export const UNI_SELECT_ID = 'uni-select-dropdown'

// Weird combo of Popover and Combobox to get the desired layout since we want the combobox input nested within the panel
// Thus combobox is marked as "static" and always open, also has autofocus so user can type immediately

export function UniDropdown() {
  const { id: uniName, node: nodeName } = useParams()
  useListUnis() // prefetch here so user doesn't have to wait when opening dropdown
  const { data: uni } = useGetUni()
  const uniAlias = uni?.getUni?.alias

  return (
    <div data-testid='uni-switcher'>
      <Popover className='relative text-left'>
        <PopoverButton id={UNI_SELECT_ID} className={'flex focus:outline-none'}>
          <div className={clsx(`text-dark hover:text-brand flex items-center gap-2`)}>
            {uniName && nodeName ? (
              <div className='mr-0.5 flex flex-col items-end justify-start gap-1.5'>
                <div className='text-light text-xs leading-none'>{uniAlias || uniName}</div>
                <div className='text-lg font-bold leading-none'>{nodeName}</div>
              </div>
            ) : (
              <div className='text-sm font-bold'>{(uniAlias || uniName) ?? 'Applications'}</div>
            )}
            <Icon name='caret-down' size={'xs'} />
          </div>
        </PopoverButton>
        <PopoverPanel
          transition
          className='transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0'
        >
          <UniDropdownCombobox />
        </PopoverPanel>
      </Popover>
    </div>
  )
}

interface Option {
  // TODO: will need a way to identify lakehouse unis from just listUnis (prefix uni name once we add alias?)
  type: 'node' | 'lakehouse-node' | 'lakehouse-uni' | 'create-uni'
  uni: ListUnis[0]
  node: NonNullable<ListUnis[0]['nodes']>[0]
}

function UniDropdownCombobox() {
  const close = useClose()
  const { listUnisQuery } = useListUnis()
  const unis = listUnisQuery?.data
  const navigate = useNavigate()
  const { id: uniName, node: nodeName } = useParams()

  const [query, setQuery] = useState('')

  const filteredNodeOptions =
    unis
      ?.map((uni) => {
        const uniNameMatch = (uni.alias || uni.name).toLowerCase().includes(query.toLowerCase())
        return {
          name: uni.name,
          sku: uni.sku,
          alias: uni.alias,
          nodeOptions:
            (uni.nodes &&
              uni.nodes
                .filter((node) => {
                  // If node is deleted, don't show it!
                  if (node.status === 'DESTROYED') {
                    return false
                  }
                  // Match uni OR node name - if uni name matches, show ALL the nodes
                  if (uniNameMatch) {
                    return true
                  }
                  return node.name.toLowerCase().includes(query.toLowerCase())
                })
                .map((node) => {
                  return {
                    type: uni?.features?.includes(UniFeatures.VENDIA_TABLES_ENABLED) ? 'lakehouse-node' : 'node',
                    uni,
                    node,
                  } as Option
                })) ??
            [],
        }
      })
      .filter((uni) => uni.nodeOptions.length > 0)
      .sort((a, b) => {
        // Sort current uni to the top
        if (a.name === uniName || a.name.replace('.unis.vendia.net', '') === uniName) {
          return -1
        }
        if (b.name === uniName || b.name.replace('.unis.vendia.net', '') === uniName) {
          return 1
        }
        // TODO: keep this logic around in case we decide to go back to treating lakehouse unis differently
        // // Sort lakehouse unis to the top (beneath current uni)
        // if (a.sku === 'LAKEHOUSE' && b.sku !== 'LAKEHOUSE') {
        //   return -1
        // }
        // if (b.sku === 'LAKEHOUSE' && a.sku !== 'LAKEHOUSE') {
        //   return 1
        // }
        return 0
      }) ?? []

  const handleChangeOption = async (option: Option) => {
    logger('handleChangeOption', option)

    // If we're already on lakehouse uni and just changing nodes, stay on tables overview page
    // Won't need this when analytical stuff is realeased and/or we have uni features on list unis query
    const isSameUni = option.uni?.name === uniName
    const isLakehousePath = window.location.pathname.split('/').includes('data')
    const isSameLakehouseUni = isSameUni && isLakehousePath

    if (!option) {
      return
    }
    if (option.type === 'create-uni') {
      navigate('/create-uni')
      close()
      return
    }
    if (option.type === 'lakehouse-node' || isSameLakehouseUni) {
      navigate(`/uni/${option.uni.name}/${option.node.name}/data`)
      close()
      return
    }
    navigate(`/uni/${option.uni.name}/${option.node.name}/entities`)
    close()
  }
  return (
    <Combobox as='div' onChange={handleChangeOption} onClose={() => setQuery('')}>
      <div className='ring-outline absolute right-0 mt-2 min-w-96 origin-top-right overflow-hidden rounded-lg bg-white shadow-2xl ring ring-1'>
        <div className='flex flex-col gap-2 rounded-t-md border-b bg-white'>
          {/* <div className='max-w-xs text-sm font-bold'>Applications</div> */}
          <div className='relative'>
            <div className='absolute flex size-16 items-center justify-center'>
              <Icon name='search' size={16} className='opacity-50' />
            </div>
            <ComboboxInput
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              placeholder='Search Applications'
              className={clsx(
                'placeholder:text-light min-h-16 w-full items-center border-none bg-slate-100 p-2 pl-14 text-sm focus:outline-none',
              )}
              aria-label='Application selector'
              onChange={(event) => setQuery(event.target.value)}
            />
          </div>
        </div>
        <ComboboxOptions static>
          <div className='max-h-[600px] overflow-y-auto bg-white'>
            {listUnisQuery.isLoading && <PageLoader />}
            {!listUnisQuery.isLoading && filteredNodeOptions.length === 0 && (
              <div className='px-4 py-16 text-center text-xs'>
                {query ? (
                  <span className='font-bold'>No applications matching "{query}"</span>
                ) : (
                  <>
                    <p>You haven't created any applications. </p>Click <strong>Create Application</strong> below to get
                    started!
                  </>
                )}
              </div>
            )}
            {filteredNodeOptions?.map((uni, i) => {
              const isSelectedUni = uni.name === uniName
              // TODO: keep this logic around in case we decide to go back to treating lakehouse unis differently
              // if (uni.sku === 'LAKEHOUSE') {
              //   return (
              //     <div
              //       className={clsx(
              //         'flex flex-col',
              //         isSelectedUni && 'border-information-1 bg-tintLight text-brand border-b-2 border-t',
              //       )}
              //       key={uni.name}
              //     >
              //       <div className='border-neutral-2 flex flex-col border-b text-xs font-semibold'>
              //         <ComboboxOption
              //           value={{
              //             type: 'lakehouse-uni',
              //             uni: uni,
              //           }}
              //           className={`data-[focus]:bg-tint border-neutral-2 flex w-full cursor-pointer items-center justify-between gap-2 border-t p-5 px-4`}
              //         >
              //           <div>{uni.alias || uni.name}</div>
              //           <div className='mr-2'>
              //             {isSelectedUni ? <Icon name='check' size={16} stroke='#0b92de' /> : null}
              //           </div>
              //         </ComboboxOption>
              //       </div>
              //     </div>
              //   )
              // }
              return (
                <div
                  className={clsx(`flex flex-col border-b py-2`, isSelectedUni && 'bg-tintLight text-brand')}
                  key={uni.name}
                >
                  <div className={`text-light flex max-w-md items-baseline truncate px-6 py-2 text-xs`}>
                    {uni.alias || uni.name}
                  </div>
                  <div className='flex flex-col text-xs font-semibold'>
                    {uni.nodeOptions.map((option) => {
                      const isSelectedNode = isSelectedUni && nodeName === option.node.name
                      return (
                        <ComboboxOption
                          key={option.node.name}
                          value={option}
                          className={`data-[focus]:bg-tintLight flex w-full cursor-pointer items-center justify-between gap-2 px-6 py-2`}
                        >
                          <div key={option.node.name}>{option.node.name}</div>
                          <div className='mr-2'>
                            {isSelectedNode ? <Icon name='check' size={16} stroke='#6800EB' /> : null}
                          </div>
                        </ComboboxOption>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
          <ComboboxOption
            value={{
              type: 'create-uni',
            }}
            className={`data-[focus]:bg-brandLight bg-brand flex cursor-pointer items-center justify-center border-t p-6 text-sm font-bold text-white`}
          >
            <Icon name='plus-m' size={14} className='mr-2' />
            Create Application
          </ComboboxOption>
        </ComboboxOptions>
      </div>
    </Combobox>
  )
}
