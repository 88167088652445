/* Roles */
import { ActionEnum, Capability, LakehouseTable, User } from '@vendia/management-api-types'

import { TableCard } from './../pages/uni-view/lakehouse/table-card'

// This is rad, should use it more, shows the final result type
//  in IDE rather than GraphqlFetchResponse<...other junk...>
// https://www.totaltypescript.com/concepts/the-prettify-helper
export type Prettify<T> = {
  [K in keyof T]: T[K]
} & {} // eslint-disable-line @typescript-eslint/ban-types

export type Actions = keyof typeof ActionEnum

export enum ActionCategory {
  USER = 'USER',
  UNI = 'UNI',
  ORG = 'ORG',
  DATA = 'DATA',
}
export type ActionCategories = keyof typeof ActionCategory

export type ActionRepresentation = `Action.${Actions}`

/* Resources */

export type NameResourceString = `${string}@${string}.${string}.${string}`

export type UniResourceString =
  | `${string}.${string}.${string}.${string}`
  | `${string}.${string}.${string}.${string}#${string}`

export type VendiaResourceString =
  | `${string}`
  | `${string}/${string}`
  | `${string}/${string}/${string}`
  | `${string}/${string}/${string}/${string}`

export type NameResourceRepresentation = `NameResource(${NameResourceString})`

export type UniResourceRepresentation = `UniResource(${UniResourceString})`

export type OrgResourceRepresentation = `OrganizationResource(${string})`

export type VendiaResourceRepresentation = `VendiaResource(${VendiaResourceString})`

export interface NameResource {
  name: string
  subdomain: string
  domain: string
  ext: string
}

export interface UniResource {
  name: string
  subdomain: string
  domain: string
  ext: string
  nodeName: string
}

export interface OrgResource {
  orgId: string
}

export interface VendiaResource {
  orgId: string
  uniName: string
  nodeName: string
}

export type Resource = NameResource | UniResource | OrgResource | VendiaResource

export type ResourceRepresentation =
  | NameResourceRepresentation
  | UniResourceRepresentation
  | OrgResourceRepresentation
  | VendiaResourceRepresentation

export type RoleRepresentation = {
  [key in ActionRepresentation]?: ResourceRepresentation[]
}

export type RoleTemplate = {
  name: string
  description: string
  capabilities?: Capability[]
}

/* User Types */

export type OrgMember = User & {
  external?: boolean
}

export enum ShareAppName {
  LakehouseApp = 'lakehouse-app',
  LakehouseIngestion = 'lakehouse-ingestion',
}

// Sting values need to match back-end feature flags
export enum UniFeatures {
  VENDIA_TABLES_ENABLED = 'vendia_tables_enabled',
}

// Data plane Table type - parsing tableConfig string into LakehouseTable type fields and
// adding them to the resulting table items
export type Table = LakehouseTable & {
  _id: string
  _owner: string
  name: string
  sourceDataUri: string
  snapshotManifestUri: string
  replicationStatus: 'IN_PROGRESS' | 'READY'

  // Not fetching these for now as not being used
  // snapshotManifestEtag: string
  // createdTime: string
  // updatedTime: string

  // Converting this to parsed properties after fetching
  // TableConfig: string
}
