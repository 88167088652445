import clsx from 'clsx'
import { hasData } from 'node_modules/cypress/types/jquery'
import { ForwardedRef, forwardRef, ReactNode } from 'react'

export type LabelNestedProps = {
  label?: ReactNode
  showNestedLabel?: boolean
  useNestedLabel?: boolean
  nestedLabelOffset?: number
}

const LabelNested = (
  { label, showNestedLabel, useNestedLabel, nestedLabelOffset }: LabelNestedProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  if (useNestedLabel && typeof label !== 'string') {
    throw new Error('Label prop must be a string with useNestedLabel')
  }

  const offsetStyle = nestedLabelOffset ? { left: `${nestedLabelOffset + 16}px` } : undefined

  if (showNestedLabel) {
    return (
      <div
        ref={ref}
        className={clsx('text-light absolute left-4 translate-y-1 text-xs capitalize')}
        style={offsetStyle}
      >
        {label}
      </div>
    )
  }
}

export default forwardRef(LabelNested)
