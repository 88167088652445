import clsx from 'clsx'
import React, { forwardRef, LegacyRef, ReactNode } from 'react'

export type LabelProps = {
  name: string
  label?: React.ReactNode
  useNestedLabel?: boolean
  disabled?: boolean
  hasDescription?: boolean
  className?: string
  description?: ReactNode
}

const Label = (
  { name, label, disabled, hasDescription, useNestedLabel, className, description }: LabelProps,
  ref: LegacyRef<HTMLLabelElement>,
) => {
  if (label) {
    return (
      <>
        <label
          htmlFor={name}
          className={clsx(
            'text-base font-bold',
            disabled ? 'text-light' : 'text-dark',
            useNestedLabel ? 'hidden' : 'block',
            !description && 'mb-2',
            className,
          )}
          ref={ref}
        >
          {label}
        </label>
        {description && <div className='text-light mb-2 text-sm'>{description}</div>}
      </>
    )
  }
}

export default forwardRef(Label)
