import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react'
import clsx from 'clsx'
import debug from 'debug'
import { forwardRef, Fragment, useRef } from 'react'
import Icon from 'src/components/icons/icon'

import { FormInputProps } from '../fields/types'

const logger = debug('app:listbox-input')

// Generic so you can type the value if it's something other than a string
export interface ListOption<ValueType = string | null> {
  value: ValueType
  label: string | JSX.Element
  description?: string
  disabled?: boolean
  divider?: boolean
}

export type ListboxInputProps = FormInputProps & {
  options: ListOption[]
  portal?: boolean
  compact?: boolean
  optionsClassName?: string
  onChange?: (selectedOption: ListOption) => void
  value?: string
  setWidthAccordingToOptions?: boolean
}

const ListboxInput = (
  {
    name,
    label,
    useNestedLabel,
    placeholder,
    options = [],
    disabled,
    className,
    optionsClassName,
    style,
    onChange,
    value,
    hasErrors,
    compact = false,
    portal = true,
    setWidthAccordingToOptions,
  }: ListboxInputProps,
  ref: any,
) => {
  if (useNestedLabel && !label) {
    throw new Error('useNestedLabel requires a label')
  }
  // Track the width of the button to set the width of the options list (when using portal)
  const buttonRef = useRef<HTMLDivElement>(null)
  const matchingItem = options.find((o) => o.value === value) as ListOption

  const calcWidthForSelect = (options: ListOption[]) => {
    const longestOption: number | undefined = options
      .map((o) => {
        if (typeof o.label === 'string') {
          return Math.max(o.label.length, o.description?.length || 0)
        }
      })
      .filter(Boolean)
      .sort((a?: number, b?: number) => a! - b!)
      .pop()

    return longestOption ? { minWidth: `${longestOption + 5}ch`, width: `${longestOption + 5}ch` } : {}
  }

  const listBoxStyle = setWidthAccordingToOptions ? calcWidthForSelect(options) : {}

  return (
    <Listbox value={matchingItem} by='value' onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <div
          className={clsx('relative', compact && 'mt-1', className)}
          style={{ ...style, ...listBoxStyle }}
          ref={buttonRef}
        >
          <ListboxButton
            ref={ref}
            id={name}
            aria-describedby={`${name}-description`}
            className={clsx(
              'relative h-12 w-full rounded-md pl-4 pr-7 shadow outline-0',
              !disabled && 'cursor-pointer bg-white',
              disabled && 'text-light cursor-not-allowed bg-slate-100',
              !hasErrors && 'ring ring-1 ring-slate-300',
              hasErrors && 'z-2 ring-2 ring-red-500',
              !compact && 'h-12 max-h-12',
              compact && 'max-h-9 min-h-9',
            )}
          >
            <div className='truncated flex flex-wrap gap-1 overflow-hidden'>
              {!matchingItem && (placeholder || useNestedLabel) && (
                <span className='text-light'>{useNestedLabel ? label : placeholder}</span>
              )}
              {useNestedLabel && matchingItem && (
                <span className={'text-light absolute top-1 bg-transparent text-xs'}>{label}</span>
              )}
              <span className={clsx('block truncate', useNestedLabel && 'text-dark mt-3')}>{matchingItem?.label}</span>
            </div>
            <span className='pointer-events-none absolute inset-y-0 right-3 flex items-center'>
              <Icon name='chevron-down' size={12} className={disabled ? 'text-light' : 'text-dark'} />
            </span>
          </ListboxButton>
          <Transition
            show={open}
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <ListboxOptions
              anchor='bottom start'
              className={clsx(
                'ring-outline absolute z-20 my-[1px] max-h-60 overflow-auto rounded-lg bg-white text-base shadow-2xl ring ring-1 focus:outline-none sm:text-sm',
                optionsClassName,
              )}
              style={{ width: buttonRef.current?.offsetWidth }}
              portal={portal}
            >
              {options.map((option, i) => {
                if (option.divider) {
                  return <hr className='my-2 w-full' key={i} />
                }
                return (
                  <ListboxOption
                    key={option.value}
                    className={({ focus }) =>
                      clsx(
                        'relative w-full cursor-pointer select-none py-2 pl-2 pr-9',
                        focus && 'bg-tintLight',
                        option.disabled ? 'text-light' : 'text-dark',
                      )
                    }
                    value={option}
                    disabled={option.disabled}
                  >
                    <>
                      <div className='flex flex-col'>
                        <span className='block truncate text-sm'>{option.label}</span>
                        <span className='text-light block text-xs'>{option.description}</span>
                      </div>
                      {matchingItem?.value === option.value ? (
                        <span className='text-brand absolute inset-y-0 right-0 flex items-center pr-4'>
                          <Icon name='success' size={18} aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  </ListboxOption>
                )
              })}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}

export default forwardRef<any, ListboxInputProps>(ListboxInput)
