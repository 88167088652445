import { ChangeEvent, ForwardedRef, forwardRef, useEffect } from 'react'

import Checkbox, { CheckboxInputProps } from '../inputs/checkbox.input'
import FieldSet from './field-set'
import { FormFieldProps } from './types'
import { onBlurHandler, useRemoveFieldOnUnmount } from './utils'

export type CheckboxFieldProps = Omit<CheckboxInputProps, 'onChange'> &
  FormFieldProps<boolean> & {
    onChange?: (checked: boolean, value?: string) => void
  }

const CheckboxField = (
  {
    form,
    name,
    wrapperClassName,
    isArrayField,
    arrayFieldName,
    arrayFieldIndex,
    label,
    description,
    validators,
    defaultValue,
    onChange,
    onBlur,
    removeFieldOnUnmount,
    checked,
    ...rest
  }: CheckboxFieldProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  useRemoveFieldOnUnmount(form, name, removeFieldOnUnmount)

  return (
    <FieldSet
      name={name}
      form={form}
      wrapperClassName={wrapperClassName}
      arrayFieldIndex={arrayFieldIndex}
      arrayFieldName={arrayFieldName}
      isArrayField={isArrayField}
      {...rest}
    >
      {(hasErrors) => (
        <form.Field name={name} validators={validators} defaultValue={defaultValue}>
          {(field) => {
            // Update field value when checked prop changes
            useEffect(() => {
              if (checked !== undefined && checked !== field.state.value) {
                field.handleChange(checked)
              }
            }, [checked, field])

            return (
              <Checkbox
                {...rest}
                ref={ref}
                name={name}
                checked={field.state.value}
                onBlur={onBlurHandler(field, onBlur)}
                onChange={(checked: boolean, value?: string) => {
                  onChange?.(checked, value)
                  field.handleChange(checked)
                }}
                label={label}
                description={description}
                hasErrors={hasErrors}
              />
            )
          }}
        </form.Field>
      )}
    </FieldSet>
  )
}

export default forwardRef<HTMLInputElement, CheckboxFieldProps>(CheckboxField)
