import { DeepKeys, DeepValue, FieldValidateFn, Validator } from '@tanstack/react-form'

export const UUID_V4_REGEX = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

export const VALID_URL_REGEX = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/

export const AWS_ACCOUNT_REGEX = /\d{12}/

export const TABLE_NAME_REGEX = /^[a-z][a-z0-9_]{0,127}$/

export function awsService(serviceSlug: string) {
  return new RegExp(
    `^(arn:(?:aws|aws-cn|aws-us-gov):${serviceSlug}:[\\w\\d-*]*:\\d{0,12}:[\\w\\d-*]*\\/?[\\w\\d-*]*)(\\/.*)?.*$`,
  )
}

export type ValidationConfig = { pattern: RegExp; message: string }

export const isRequiredValidator =
  (message?: string | null, shouldValidate = true) =>
  ({ value }: { value: string }) => {
    if (!shouldValidate) return undefined
    if (!value || value === '' || value.length === 0) {
      return message ?? 'This field is required'
    }
  }

export const isValidTableName = ({ value }: { value: string }) => {
  if (!TABLE_NAME_REGEX.test(value)) {
    return 'Table name must start with a lowercase letter and contain only lowercase letters, numbers, and underscores'
  }
}

export const createValidatorFunction = <
  TParentData,
  TName extends DeepKeys<TParentData>,
  TFieldValidator extends Validator<DeepValue<TParentData, TName>, unknown> | undefined = undefined,
  TFormValidator extends Validator<TParentData, unknown> | undefined = undefined,
  TData extends DeepValue<TParentData, TName> = DeepValue<TParentData, TName>,
>(
  config?: ValidationConfig | ValidationConfig[],
  required = false,
) => {
  return (({ value }: { value: string }) => {
    if (!value || value === '') {
      return required ? 'This field is required' : undefined
    }

    if (!config) return undefined

    if (Array.isArray(config)) {
      return config.find(({ pattern, message }) => (!pattern.test(value) ? message : undefined))
    }
    return !config.pattern.test(value) ? config.message : undefined
  }) as FieldValidateFn<TParentData, TName, TFieldValidator, TFormValidator, TData>
}

// Create a validator function that can be used in the tanstack form input
export const createValidator = (cause: string, config?: ValidationConfig | ValidationConfig[], required = false) => ({
  [cause]: createValidatorFunction(config, required),
})

export const createOnBlurValidator = (config?: ValidationConfig | ValidationConfig[], required = false) =>
  createValidator('onBlur', config, required)

export const isRequiredOnBlur = createOnBlurValidator(undefined, true)

export const createOnChangeValidator = (config?: ValidationConfig | ValidationConfig[], required = false) =>
  createValidator('onChange', config, required)

export const isRequiredOnChange = createOnChangeValidator(undefined, true)
