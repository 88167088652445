import { FieldApi, Validator } from '@tanstack/react-form'
import { FocusEvent, FocusEventHandler, FormEvent, useEffect } from 'react'

import { VendiaFormApi } from './form'

export const onBlurHandler = (field: FieldApi<any, any>, onBlur?: FocusEventHandler) => (e: FocusEvent) => {
  if (onBlur) {
    onBlur(e)
  }
  field.handleBlur()
}

export const onChangeHandler =
  <T = string | number>(field: FieldApi<any, any>, onChange?: (value: T, previousValue?: T) => void) =>
  (value: T) => {
    // Clear all errors when the value changes
    field.setMeta({ ...field.state.meta, errors: [], errorMap: {} })
    if (onChange) {
      onChange(value, field.state.value)
    }
    field.handleChange(value)
  }

export const submitHandler =
  <TFormData, TFormValidator extends Validator<TFormData, unknown> | undefined = undefined>(
    form: VendiaFormApi<TFormData, TFormValidator>,
  ) =>
  (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    form.handleSubmit()
  }

export const useRemoveFieldOnUnmount = (form: VendiaFormApi<any>, name: string, removeFieldOnUnmount = false) => {
  useEffect(() => {
    return () => {
      if (removeFieldOnUnmount) {
        // Remove from form when unmounted
        form.deleteField(name)
      }
    }
  }, [])
}
