import { ReactFormApi } from '@tanstack/react-form'
import clsx from 'clsx'
import { ReactElement, useRef } from 'react'
import { getValue } from 'src/utils/form/form'

import ErrorMessage from './error-message'
import Label from './label'
import LabelNested from './label-nested'
import { FieldSetProps, FormInputProps } from './types'

const FieldSet = <T extends HTMLElement = HTMLInputElement>({
  name,
  wrapperClassName,
  labelClassName,
  children,
  useNestedLabel,
  nestedLabelRequiresOffset,
  label,
  description,
  form,
  isArrayField,
  arrayFieldName,
  arrayFieldIndex,
  fieldNameKey,
  labelRef,
  inputRef,
  nestedLabelRef,
  errorRef,
}: FieldSetProps &
  Omit<FormInputProps, 'children'> & {
    form: ReactFormApi<unknown>
    fieldNameKey?: string
    children: (hasErrors: boolean) => ReactElement
  }) => {
  if (!children) {
    throw new Error(`Form input was not provided for ${name}`)
  }
  if (useNestedLabel && !label) {
    throw new Error(`Label is required for nested label`)
  }
  if (isArrayField && !arrayFieldName) {
    throw new Error(`Array field name is required for array field`)
  }
  if (isArrayField && arrayFieldIndex === undefined) {
    throw new Error(`Array field index is required for array field`)
  }

  // This will detect the position of the input element and adjust the nested label position
  // to allow for addition left content
  const containerRef = useRef<HTMLDivElement>(null)
  let nestedLabelOffset = 0
  if (inputRef && useNestedLabel && nestedLabelRequiresOffset) {
    const inputLeft = inputRef.current?.getBoundingClientRect().left
    const containerLeft = containerRef.current?.getBoundingClientRect().left ?? 0
    if (inputLeft) {
      nestedLabelOffset = inputLeft - containerLeft
    }
  }

  const hasErrors = form?.useStore((s) => {
    const { errors, isDirty, isBlurred } = s.fieldMeta[name] ?? {}
    // logger('hasErrors', { name, errors, isDirty, isBlurred })
    return errors?.length > 0 && (isDirty || isBlurred)
  })

  const fieldValue = form?.useStore((state) => {
    let path = name
    if (isArrayField) {
      path = fieldNameKey
        ? `${arrayFieldName}.${arrayFieldIndex}.${fieldNameKey}`
        : `${arrayFieldName}.${arrayFieldIndex}`
    }
    return getValue(state.values as any, path)
  })
  const hasDescription = Boolean(description)

  return (
    <div className={clsx('relative mb-3 w-full', wrapperClassName)} ref={containerRef}>
      <Label
        ref={labelRef}
        name={name}
        label={label}
        useNestedLabel={useNestedLabel}
        hasDescription={hasDescription}
        className={labelClassName}
        description={description}
      />
      <LabelNested
        label={label}
        useNestedLabel={useNestedLabel}
        showNestedLabel={useNestedLabel && Boolean(fieldValue)}
        nestedLabelOffset={nestedLabelOffset}
        ref={nestedLabelRef}
      />
      {children(hasErrors)}
      <ErrorMessage name={name} form={form} ref={errorRef} hasErrors={hasErrors} />
    </div>
  )
}

export default FieldSet
