import { FormOptions, FormValidators, useForm, ValidationCause } from '@tanstack/react-form'
import clsx from 'clsx'
import debug from 'debug'
import { useEffect, useState } from 'react'

import { FlowModel, FlowStep } from './types'

const logger = debug('app:multistep')

interface MultiStepFlowProps<TFormData> {
  flowSteps: FlowStep<TFormData>[]
  onSubmit?: FormOptions<TFormData & FlowModel<TFormData>>['onSubmit']
  initialFlowState?: Partial<TFormData & FlowModel<TFormData>>
  initialStepIndex?: number
  // Will automatically be hidden if there is only one step, but can be forced hidden with this
  hideStepNav?: boolean
  // form level validators to run on submit
  validators?: FormValidators<TFormData & FlowModel<TFormData>>
  // Step to show after the form is submitted successfully
  confirmationStep?: string
}

function MultiStepFlow<TFormData = any>({
  flowSteps,
  initialFlowState = {},
  hideStepNav = false,
  initialStepIndex = 0,
  validators,
  onSubmit,
  confirmationStep,
}: MultiStepFlowProps<TFormData>) {
  const defaultValues = {
    currStep: flowSteps[initialStepIndex],
    currStepIndex: initialStepIndex,
    flowSteps,
    ...initialFlowState,
  } as TFormData & FlowModel<TFormData>

  const form = useForm<TFormData & FlowModel<TFormData>>({
    defaultState: { canSubmit: false, isSubmitting: false },
    defaultValues,
    validators,
    onSubmit,
    onSubmitInvalid,
  })

  // If the form is submitted and a confirmation step is provided, go to that step
  const isSubmitted = form.useStore((s) => s.isSubmitted)
  useEffect(() => {
    if (isSubmitted && confirmationStep) {
      goToStep(confirmationStep)
    }
  }, [isSubmitted, confirmationStep])

  const { currStep, currStepIndex } = form.useStore((s) => s.values)
  const showStepNav = flowSteps.length > 1 && !hideStepNav && currStep.showStepNav !== false
  const [stepHistoryStack, setStepHistoryStack] = useState<string[]>([])

  function resetValidationErrors() {
    const resetMeta = form.resetFieldMeta(form.state.fieldMeta)
    const fieldMeta = Object.entries(resetMeta).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: { ...(value as any), errors: [], errorMap: {} },
      }),
      resetMeta,
    )
    form.store.setState((prev) => ({ ...prev, fieldMeta }))
  }

  function onSubmitInvalid() {
    logger('Form is invalid, redirecting to first invalid step')
    goToStep(currStep.id)
  }

  function setCurrStepIndex(stepIndex: number) {
    if (stepIndex < 0 || stepIndex >= flowSteps.length) {
      throw new Error(`Step with id ${stepIndex} not found`)
    }

    // TODO: the deep keys type is not working as expected
    const theForm = form as any
    theForm.setFieldValue('currStep', flowSteps[stepIndex])
    theForm.setFieldValue('currStepIndex', stepIndex)
    theForm.setFieldValue('flowSteps', flowSteps)
  }

  const validateFlowState = async (reason: ValidationCause = 'change') => {
    // run the individual field validators, returns array of errors
    const fieldErrors = await form.validateAllFields(reason)
    if (fieldErrors.length > 0) {
      return { fieldErrors, formErrors: {}, hasErrors: true, isValid: false }
    }
    // run the form level validator, returns object with field names as keys and error messages as values
    const formErrors = await form.validate(reason)
    const hasErrors = Object.keys(formErrors).length > 0
    // Return the errors and whether the form is valid as convenience
    logger('validate flow state', { fieldErrors, formErrors, hasErrors, isValid: !hasErrors })
    return { formErrors, hasErrors, isValid: !hasErrors, fieldErrors: [] }
  }

  // validates the current step and moves to the next step if valid
  // also reruns validation on the next step
  const goNext = async () => {
    let validation = await validateFlowState()
    const nextStep = flowSteps[currStepIndex + 1]
    logger('goNext start', {
      validation,
      nextStep,
      isValid: validation.isValid,
      showInStepNav: nextStep.showInStepNav,
    })

    // Only progress to the next step if the current step is valid or if the next step is a child step
    if (validation.isValid || nextStep.showInStepNav === false) {
      logger('goNext valid')
      setStepHistoryStack((currentSteps) => [...currentSteps, currStep.id])
      setCurrStepIndex(currStepIndex + 1)
      setTimeout(validateFlowState)
    }
  }

  const goPrevious = async () => {
    resetValidationErrors()
    const previousStepId = stepHistoryStack.pop()
    setStepHistoryStack(() => [...stepHistoryStack])
    if (previousStepId) {
      setCurrStepIndex(flowSteps.findIndex((s) => s.id === previousStepId))
    }
    setTimeout(validateFlowState)
  }

  // Returns the first invalid step and its index, starting from the beginning of the flow
  // TODO: this currently only includes steps that have been rendered so that the fields have been registered
  // with the form.
  const getFirstInvalidStep = async (stepId: string) => {
    const steps = form.state.values.flowSteps as FlowStep<TFormData>[]
    for (const [index, step] of steps.entries()) {
      setCurrStepIndex(index)
      const errors = await validateFlowState()
      if (errors.hasErrors || step.id === stepId) return { step, index }
    }
    return { step: steps[steps.length - 1], index: steps.length - 1 }
  }

  // Attempts to navigate to the provided stepId by iterating through
  // the flowSteps previous to the stepId and validating them first.
  const goToStep = async (stepId: string) => {
    let nextStepIndex = flowSteps.findIndex((s) => s.id === stepId)
    const nextStep = flowSteps[nextStepIndex]

    if (nextStepIndex < currStepIndex) {
      resetValidationErrors()
    }

    // child steps could make their parent step invalid,
    // so we only block the user from moving forward if the parent step is invalid
    if (nextStep.showInStepNav) {
      const { index } = await getFirstInvalidStep(stepId)
      nextStepIndex = index
    } else {
      validateFlowState()
    }

    // If the next step is already in our step history, assume we are
    // "going back" to that step, and chop off the steps after it. Otherwise just
    // add it to the history stack as normal.
    setStepHistoryStack((currentSteps) => {
      if (currentSteps.includes(stepId)) {
        return currentSteps.slice(0, currentSteps.indexOf(stepId))
      } else {
        return [...currentSteps, currStep.id]
      }
    })
    setCurrStepIndex(nextStepIndex)
  }

  const handleSubmit = () => form.handleSubmit()

  // Some steps are like sub-steps (pre-steps, post-steps, whatever) - we want to step through them like normal,
  // but not muck up the top stepper nav with extra steps. So we filter them out here.
  const filteredflowStepsForNav: FlowStep<TFormData>[] = flowSteps.filter((s) => s.showInStepNav !== false)

  // When a step has showInStepNav===false, we want to remain on the same step index for consecutive steps
  const currentNavStepIndex = flowSteps.reduce((acc, curr, stepIndex): number => {
    if (curr.showInStepNav === false && stepIndex <= currStepIndex) {
      return acc - 1
    }
    return acc
  }, currStepIndex)

  const wrapperClasses = clsx('flex w-full flex-1 flex-col')

  return (
    <div className={wrapperClasses}>
      <div className={'flex w-full flex-1 flex-col items-center justify-center'}>
        <div
          className={`mb-6 mt-2 flex w-full max-w-md items-center justify-evenly font-bold text-white ${
            !showStepNav ? '!mb-0 hidden' : ''
          }`}
        >
          {filteredflowStepsForNav.map(({ showInStepNav, id }, stepIndex) => {
            if (showInStepNav === false) {
              return null
            }
            return (
              <div
                key={id}
                className={clsx(
                  'h-2 w-full first:rounded-l-full last:rounded-r-full',
                  stepIndex > currentNavStepIndex && 'bg-slate-200',
                  stepIndex <= currentNavStepIndex && 'bg-brand',
                )}
              />
            )
          })}
        </div>
        <div className={'flex w-full flex-1 flex-col items-center justify-center'}>
          <currStep.StepComponent
            context={{
              form,
              goNext,
              goPrevious,
              goToStep,
              validateFlowState,
              handleSubmit,
              getFirstInvalidStep,
              resetValidationErrors,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default MultiStepFlow
